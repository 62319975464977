import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Хэлцэл баталгаажуулалт",
  "description": null,
  "author": "OTC help",
  "category": "my-deals",
  "date": "2022-04-08T00:00:00.000Z",
  "featured": true,
  "tags": [],
  "order": 2
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Хэлцэл баталгаажуулалт`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Үндсэн цэсний `}<strong parentName="p">{`[My Deals]`}{`-`}{`[Settlement]`}</strong>{` хэсэгт платформоор дамжуулан хоёр тал зөвшөөрсөн хэлцэлүүдийг дэлгэрэнгүй мэдээллийн хамт харах боломжтой.`}</p>
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`[Trade ID]`}</strong>{` хэлцлийн дугаар`}</li>
          <li parentName="ul"><strong parentName="li">{`[Symbol]`}</strong>{` үнэт цаасны симбол (үсгэн код)`}</li>
          <li parentName="ul"><strong parentName="li">{`[Side]`}</strong>{` тухайн оролцогчийн авах/зарах талыг илтгэнэ`}</li>
          <li parentName="ul"><strong parentName="li">{`[Quantity]`}</strong>{` хэлцлийн тоо ширхэг`}</li>
          <li parentName="ul"><strong parentName="li">{`[Price]`}</strong>{` харилцан тохирсон нэгжийн үнэ`}</li>
          <li parentName="ul"><strong parentName="li">{`[Total amount]`}</strong>{` хэлцлийн нийт дүн`}</li>
          <li parentName="ul"><strong parentName="li">{`[Counterparty]`}</strong>{` хэлцэл хийсэн тал`}</li>
          <li parentName="ul"><strong parentName="li">{`[Trade date]`}</strong>{` хоёр тал хэлцэл баталгаажуулсан огноо, цаг`}</li>
          <li parentName="ul"><strong parentName="li">{`[OTC status]`}</strong>{` платформ дахь төлөв`}</li>
          <li parentName="ul"><strong parentName="li">{`[Seller Client ID]`}</strong>{` зарах талын харилцагчийн бүртгэлийн дугаар`}</li>
          <li parentName="ul"><strong parentName="li">{`[Buyer Client ID]`}</strong>{` авах талын харилцагчийн бүртгэлийн дугаар`}</li>
          <li parentName="ul"><strong parentName="li">{`[Seller Note]`}{`, `}{`[Buyer Note]`}</strong>{` авах зарах талын төлөв, тэмдэглэгээ`}</li>
          <li parentName="ul"><strong parentName="li">{`[CSD Status]`}</strong>{` кастодиан дахь хэлцлийн төлөв. Хэлцлийн илгээсэн хугацаа мэдээлэл, төлбөр тооцооны байдлаас хамааран `}{`[Received, Confirmed, Failed, Pending, Invalid, Warning]`}{` зэрэг төлөв харагдана.`}</li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Оролцогч талууд илгээгдсэн буюу `}<strong parentName="p">{`[Settlement]`}</strong>{` хэсэг дэх хэлцлийг буцаах, цуцлах эрхгүй болно.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`[CSD status]`}</strong>{` нь `}<strong parentName="p">{`[Warning]`}</strong>{` буюу анхааруулга төлөвт байгаа тохиолдолд өөрчлөх шаардлагатай мэдээллийн хэсэгт `}<strong parentName="p">{`[Update]`}</strong>{` товч идэвхэжнэ. Оролцогч тус товчийг дарж мэдээллийг засварлан `}<strong parentName="p">{`[Submit]`}</strong>{` дарж хүсэлтийг дахин илгээнэ.`}</p>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      